import { OutlineChevronRightIcon } from "@/components/Icons/components/HeroIcons/OutlineChevronRightIcon.component";
import { Link } from "@/components/Link/Link.component";
import clsx from "clsx";
type BorderHeadingProps = {
  readonly children: JSX.Element | string;
  readonly targetUrl?: null | string;
  readonly position?: string;
};
export function BorderHeading({
  children,
  position,
  targetUrl = null
}: BorderHeadingProps) {
  const defaultClassNames = "block border-b-[line-width:var(--theme-grid-lg)] border-solid border-primary pb-1 mb-2";
  if (targetUrl !== null) {
    return <Link className={clsx(defaultClassNames, "hover:underline flex items-center")} href={targetUrl} openInNewTab="determineFromSource" position={position}>
        <h1 className="text-lg font-theme-bold uppercase leading-none">
          {children}
        </h1>
        <OutlineChevronRightIcon className="w-2 h-2" />
      </Link>;
  }
  return <h1 className={clsx(defaultClassNames, "select-none text-lg font-theme-bold uppercase")} data-sentry-component="BorderHeading" data-sentry-source-file="BorderHeading.component.tsx">
      {children}
    </h1>;
}