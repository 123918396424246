"use client";

import { BorderHeading } from "@/app/(sites)/tabloid/_components/headings/BorderHeading.component";
import { Card } from "@/components/Card/Card.component";
import { CardBody } from "@/components/Card/_components/CardBody/CardBody.component";
import { Carousel, CarouselContent, CarouselItem, CarouselNext, CarouselPrevious } from "@/components/Carousel/Carousel.component";
import { Translation } from "@/components/Translation/Translation.component";
import { useSite } from "@/contexts/site/site.context";
import { useKilkayaClickEvent } from "@/hooks/tracking/kilkaya/use-kilkaya-tracking.hook";
import { useTracking } from "@/hooks/tracking/use-tracking";
import { useTrackingVersion } from "@/hooks/tracking/use-tracking-version";
import type { JobModel } from "@/services/api/content/models/job.model";
import { usePathname } from "next/navigation";
import { JobItem } from "../JobItem/JobItem.component";
type JobCarouselProps = {
  readonly jobs: JobModel;
};
export function JobCarousel({
  jobs
}: JobCarouselProps) {
  const {
    domain
  } = useSite();
  const pathname = usePathname();
  const {
    trackClick: trackClickV2
  } = useTracking();
  const {
    trackClick: trackClickV1
  } = useKilkayaClickEvent();
  const trackingVersion = useTrackingVersion();
  let nextCounter = 0;
  return <Carousel opts={{
    slidesToScroll: 1,
    breakpoints: {
      "(min-width: 40rem)": {
        slidesToScroll: 2
      },
      "(min-width: 64rem)": {
        slidesToScroll: 3
      },
      "(min-width: 80rem)": {
        slidesToScroll: 4
      }
    },
    dragFree: true
  }} className="flex flex-col after:contents-[''] after:w-full lg:after:h-px after:bg-primary after:mt-2" data-sentry-element="Carousel" data-sentry-component="JobCarousel" data-sentry-source-file="JobCarousel.component.tsx">
      <div className="relative">
        <BorderHeading targetUrl={jobs.link?.url} position="top-a" data-sentry-element="BorderHeading" data-sentry-source-file="JobCarousel.component.tsx">
          <Translation da="Job" de="Stellenanzeigen" en="Jobs" no="Stillinger" sv="Jobb" data-sentry-element="Translation" data-sentry-source-file="JobCarousel.component.tsx" />
        </BorderHeading>
        <div className="self-end items-center justify-between gap-1 absolute -top-3 lg:-top-2.5 right-0 hidden sm:flex">
          <CarouselPrevious data-sentry-element="CarouselPrevious" data-sentry-source-file="JobCarousel.component.tsx" />
          <CarouselNext onClick={() => {
          nextCounter++;
          if (trackingVersion === "v2") {
            trackClickV2({
              position: `job_next-${nextCounter}`,
              toUrl: `https://${domain}${pathname}`,
              url: `https://${domain}${pathname}`
            });
          }
          if (trackingVersion === "v1") {
            trackClickV1({
              position: `job_next-${nextCounter}`,
              toUrl: `https://${domain}${pathname}`,
              url: `https://${domain}${pathname}`
            });
          }
        }} data-sentry-element="CarouselNext" data-sentry-source-file="JobCarousel.component.tsx" />
        </div>
      </div>
      <CarouselContent data-sentry-element="CarouselContent" data-sentry-source-file="JobCarousel.component.tsx">
        {jobs.list.map((job, index) => <CarouselItem className="basis-[80%] sm:basis-[40%] lg:basis-[28.5%] xl:basis-[22.2%]" key={job.id}>
            <Card className="h-full !p-0" position={`job-${index + 1}`}>
              <CardBody className="overflow-hidden">
                <JobItem headline={job.headline} id={job.id} image={job.image} targetUrl={job.targetUrl} className="p-2 h-full" placement="frontpage" />
              </CardBody>
            </Card>
          </CarouselItem>)}
      </CarouselContent>
    </Carousel>;
}