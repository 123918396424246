import clsx from "clsx";
import type { ReactNode } from "react";
import { Link } from "../Link/Link.component";
type CardProps = {
  readonly children: ReactNode;
  readonly className?: string;
  readonly elevated?: boolean;
  readonly href?: null | string;
  readonly variant?: "default" | "inverted";
  readonly position?: string;
};
export function Card({
  children,
  className,
  href = null,
  variant = "default",
  elevated = false,
  position
}: CardProps) {
  const CardComponent = href !== null ? Link : "article";
  return <CardComponent className={clsx("flex flex-col justify-between gap-3 rounded-sm", variant === "inverted" && "border-muted bg-muted p-3 text-white", variant === "default" && "border border-silver bg-white p-3", elevated && "shadow-lg", className)} data-k5a-pos={position} href={href ?? "/"} data-sentry-element="CardComponent" data-sentry-component="Card" data-sentry-source-file="Card.component.tsx">
      {children}
    </CardComponent>;
}