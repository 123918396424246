"use client";

import { Link } from "@/components/Link/Link.component";
import { useImageConfiguration } from "@/contexts/image-configuration/image-configuration.context";
import { imageLoader } from "@/utilities/image-loader";
import { clsx } from "clsx";
import NextImage from "next/image";
type EventTeaserListItemProps = {
  readonly className?: string;
  readonly title: string;
  readonly image: string;
  readonly targetUrl: string;
  readonly hasImagePriority: boolean;
  readonly sourceSetSize: string;
  readonly position?: string;
};
export function EventTeaserListItem({
  className,
  hasImagePriority,
  image,
  position,
  sourceSetSize,
  targetUrl,
  title
}: EventTeaserListItemProps) {
  const {
    serverUrl
  } = useImageConfiguration();
  return <li className={clsx(className, "hover:underline")} data-sentry-component="EventTeaserListItem" data-sentry-source-file="EventTeaserListItem.component.tsx">
      <article data-k5a-pos={position}>
        <Link className="grid grid-cols-5 gap-x-1 lg:grid-cols-1" href={targetUrl} openInNewTab={true} rel="noreferrer" data-sentry-element="Link" data-sentry-source-file="EventTeaserListItem.component.tsx">
          {image ? <figure className="relative col-span-2 block aspect-4/3 lg:col-span-full">
              <NextImage alt={title} className="bg-whisper object-cover" fill={true} loader={imageLoader(serverUrl)} priority={hasImagePriority} sizes={sourceSetSize} src={image} />
            </figure> : null}
          <h1 className="col-span-3 mt-1 hyphens-manual break-words font-heading text-lg font-theme-bold lg:col-span-full" dangerouslySetInnerHTML={{
          // biome-ignore lint/style/useNamingConvention: Biome does not support
          __html: title
        }} />
        </Link>
      </article>
    </li>;
}