"use client";

import clsx from "clsx";
import useEmblaCarousel, { type UseEmblaCarouselType } from "embla-carousel-react";
import { type ComponentProps, type HTMLAttributes, type KeyboardEvent, type MouseEvent, createContext, forwardRef, useCallback, useContext, useEffect, useState } from "react";
import { OutlineChevronLeftIcon } from "../Icons/components/HeroIcons/OutlineChevronLeftIcon.component";
import { OutlineChevronRightIcon } from "../Icons/components/HeroIcons/OutlineChevronRightIcon.component";
import { Button } from "./Button.component";
type CarouselApi = UseEmblaCarouselType[1];
type UseCarouselParameters = Parameters<typeof useEmblaCarousel>;
type CarouselOptions = UseCarouselParameters[0];
type CarouselPlugin = UseCarouselParameters[1];
type CarouselProps = {
  opts?: CarouselOptions;
  plugins?: CarouselPlugin;
  orientation?: "horizontal" | "vertical";
  setApi?: (api: CarouselApi) => void;
};
type CarouselContextProps = {
  carouselRef: ReturnType<typeof useEmblaCarousel>[0];
  api: ReturnType<typeof useEmblaCarousel>[1];
  scrollPrev: () => void;
  scrollNext: () => void;
  canScrollPrev: boolean;
  canScrollNext: boolean;
} & CarouselProps;
const CarouselContext = createContext<CarouselContextProps | null>(null);
function useCarousel() {
  const context = useContext(CarouselContext);
  if (!context) {
    throw new Error("useCarousel must be used within a <Carousel />");
  }
  return context;
}
const Carousel = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement> & CarouselProps>(({
  orientation = "horizontal",
  opts,
  setApi,
  plugins,
  className,
  children,
  ...props
}, ref) => {
  const [carouselRef, api] = useEmblaCarousel({
    ...opts,
    axis: orientation === "horizontal" ? "x" : "y"
  }, plugins);
  const [canScrollPrev, setCanScrollPrev] = useState(false);
  const [canScrollNext, setCanScrollNext] = useState(false);
  const onSelect = useCallback((api: CarouselApi) => {
    if (!api) {
      return;
    }
    setCanScrollPrev(api.canScrollPrev());
    setCanScrollNext(api.canScrollNext());
  }, []);
  const scrollPrev = useCallback(() => {
    api?.scrollPrev();
  }, [api]);
  const scrollNext = useCallback(() => {
    api?.scrollNext();
  }, [api]);
  const handleKeyDown = useCallback((event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "ArrowLeft") {
      event.preventDefault();
      scrollPrev();
    } else if (event.key === "ArrowRight") {
      event.preventDefault();
      scrollNext();
    }
  }, [scrollPrev, scrollNext]);
  useEffect(() => {
    if (!(api && setApi)) {
      return;
    }
    setApi(api);
  }, [api, setApi]);
  useEffect(() => {
    if (!api) {
      return;
    }
    onSelect(api);
    api.on("reInit", onSelect);
    api.on("select", onSelect);
    return () => {
      api?.off("select", onSelect);
    };
  }, [api, onSelect]);
  return <CarouselContext.Provider value={{
    carouselRef,
    api: api,
    opts,
    orientation: orientation || (opts?.axis === "y" ? "vertical" : "horizontal"),
    scrollPrev,
    scrollNext,
    canScrollPrev,
    canScrollNext
  }}>
        <div ref={ref} onKeyDownCapture={handleKeyDown} className={clsx("relative", className)} aria-roledescription="carousel" {...props}>
          {children}
        </div>
      </CarouselContext.Provider>;
});
Carousel.displayName = "Carousel";
const CarouselContent = forwardRef<HTMLDivElement & HTMLUListElement, HTMLAttributes<HTMLDivElement & HTMLUListElement>>(({
  className,
  ...props
}, ref) => {
  const {
    carouselRef,
    orientation,
    canScrollNext,
    canScrollPrev
  } = useCarousel();
  return <div ref={carouselRef} className={clsx("overflow-hidden relative isolate", canScrollNext && "after:content-[''] after:block after:absolute after:w-3 after:h-full after:right-0 after:bg-gradient-to-l after:top-0 after:from-white after:to-transparent after:z-10", canScrollPrev && "before:content-[''] before:block before:absolute before:w-3 before:h-full before:left-0 before:bg-gradient-to-l before:top-0 before:from-transparent before:to-white before:z-10", className)} {...props}>
      <ul ref={ref} className={clsx("flex", orientation === "horizontal" ? "-ml-[calc(1rem_-_1px)]" : "-mt-2 flex-col", className)} {...props} />
    </div>;
});
CarouselContent.displayName = "CarouselContent";
const CarouselItem = forwardRef<HTMLLIElement, HTMLAttributes<HTMLLIElement>>(({
  className,
  ...props
}, ref) => {
  const {
    orientation
  } = useCarousel();
  return <li ref={ref} role="group" aria-roledescription="slide" className={clsx("min-w-0 shrink-0 grow-0", orientation === "horizontal" ? "pl-2" : "pt-2", className)} {...props} />;
});
CarouselItem.displayName = "CarouselItem";
const CarouselPrevious = forwardRef<HTMLButtonElement, ComponentProps<typeof Button>>(({
  className,
  variant = "outline",
  size = "icon",
  onClick,
  ...props
}, ref) => {
  const {
    scrollPrev,
    canScrollPrev
  } = useCarousel();
  function handleOnClick(event: MouseEvent<HTMLButtonElement>) {
    onClick?.(event);
    scrollPrev();
  }
  return <Button ref={ref} variant={variant} size={size} className={className} disabled={!canScrollPrev} onClick={handleOnClick} {...props}>
        <OutlineChevronLeftIcon strokeWidth={2} className="h-4 w-4" />
        <span className="sr-only">Previous slide</span>
      </Button>;
});
CarouselPrevious.displayName = "CarouselPrevious";
const CarouselNext = forwardRef<HTMLButtonElement, ComponentProps<typeof Button>>(({
  className,
  variant = "outline",
  size = "icon",
  onClick,
  ...props
}, ref) => {
  const {
    scrollNext,
    canScrollNext
  } = useCarousel();
  function handleOnClick(event: MouseEvent<HTMLButtonElement>) {
    onClick?.(event);
    scrollNext();
  }
  return <Button ref={ref} variant={variant} size={size} className={className} disabled={!canScrollNext} onClick={handleOnClick} {...props}>
        <OutlineChevronRightIcon strokeWidth={2} className="h-4 w-4" />
        <span className="sr-only">Next slide</span>
      </Button>;
});
CarouselNext.displayName = "CarouselNext";
export { Carousel, CarouselContent, CarouselItem, CarouselPrevious, CarouselNext };