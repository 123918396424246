import clsx from "clsx";
import type { ReactNode } from "react";
type CardBodyProps = {
  readonly className?: string;
  readonly children: ReactNode;
};
export function CardBody({
  children,
  className
}: CardBodyProps) {
  return <main className={clsx(className, "flex-auto")} data-sentry-component="CardBody" data-sentry-source-file="CardBody.component.tsx">{children}</main>;
}